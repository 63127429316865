export const snackBarOptions = {
    position: 'top-center',
    style: {
        fontFamily: 'Ubuntu',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '18px',
        textAlign: 'center',
        color: '#FFE074',
        backgroundColor: '#201627',
        borderRadius: '24px',
        padding: '10px 20px',
        marginTop: '50px',
        minWidth: 'fit-content',
        zIndex: 5,
    },
};
