import { useEffect, useState } from 'react';
import loaderStyles from './loader.module.css';

export const AppLoader = ({ text = 'Loading' }) => {
    return (
        <div className={`${loaderStyles.loaderMainDiv}`}>
            <div
                className={` d-flex justify-content-center align-items-center`}
                style={{ height: '100vh' }}
            >
                <div
                    className={`${loaderStyles.loadingBackground} d-flex justify-content-center align-items-center`}
                >
                    <div className="text-center">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    margin: 'auto',
                                    background: '#201627',
                                    display: 'block',
                                }}
                                width="50px"
                                height="50px"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="xMidYMid"
                            >
                                <defs>
                                    <filter
                                        id="ldio-pykm2m4f3o-filter"
                                        filterUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="100"
                                        height="100"
                                    >
                                        <feGaussianBlur
                                            in="SourceGraphic"
                                            stdDeviation="3"
                                        ></feGaussianBlur>
                                        <feComponentTransfer result="cutoff">
                                            <feFuncA
                                                type="linear"
                                                slope="10"
                                                intercept="-5"
                                            ></feFuncA>
                                        </feComponentTransfer>
                                    </filter>
                                </defs>
                                <g filter="url(#ldio-pykm2m4f3o-filter)">
                                    <circle
                                        cx="46"
                                        cy="144.83004588544827"
                                        r="41"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="144.83004588544827;-32.702918781806744"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6629784943872612s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="41;0;0"
                                            keyTimes="0;0.7190476756245613;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6629784943872612s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="37"
                                        cy="134.38509793940074"
                                        r="32"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="134.38509793940074;-17.09585022083265"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.31517396299202094s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="32;0;0"
                                            keyTimes="0;0.6204103003007514;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.31517396299202094s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="62"
                                        cy="152.92722273113876"
                                        r="34"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="152.92722273113876;-33.717630921508295"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9525929127441941s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="34;0;0"
                                            keyTimes="0;0.7325591758785582;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9525929127441941s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="46"
                                        cy="144.33580638884158"
                                        r="38"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="144.33580638884158;-28.186940435062766"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5241052471841843s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="38;0;0"
                                            keyTimes="0;0.7570220639701915;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5241052471841843s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="26"
                                        cy="118.87643976802906"
                                        r="16"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="118.87643976802906;-9.210126592191148"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.11790119301322388s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="16;0;0"
                                            keyTimes="0;0.8375620764967255;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.11790119301322388s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="85"
                                        cy="126.21897151715241"
                                        r="11"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="126.21897151715241;0.6873160291379072"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6637600769616712s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="11;0;0"
                                            keyTimes="0;0.5840826920118071;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6637600769616712s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="88"
                                        cy="110.41220464181028"
                                        r="6"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="110.41220464181028;9.839286128165874"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9528562352770702s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="6;0;0"
                                            keyTimes="0;0.6434864838817717;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9528562352770702s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="47"
                                        cy="144.2200213867696"
                                        r="44"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="144.2200213867696;-24.76530529146359"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.19543252368797281s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="44;0;0"
                                            keyTimes="0;0.5329272086377432;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.19543252368797281s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="50"
                                        cy="156.74067462310182"
                                        r="47"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="156.74067462310182;-44.04131402325088"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5178281474027078s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="47;0;0"
                                            keyTimes="0;0.981036763459444;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5178281474027078s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="49"
                                        cy="132.80467192466287"
                                        r="23"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="132.80467192466287;-17.3418495941632"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7859680283158725s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="23;0;0"
                                            keyTimes="0;0.5975423466610256;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7859680283158725s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="38"
                                        cy="150.1958398532733"
                                        r="32"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="150.1958398532733;-17.063239375734444"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6132985786282388s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="32;0;0"
                                            keyTimes="0;0.7475860810621655;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6132985786282388s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="63"
                                        cy="131.3614430947036"
                                        r="16"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="131.3614430947036;-0.8665435193286974"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3151447463130994s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="16;0;0"
                                            keyTimes="0;0.5867731667755363;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3151447463130994s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="47"
                                        cy="139.56679657290312"
                                        r="37"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="139.56679657290312;-26.81755309282847"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.987563416897393s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="37;0;0"
                                            keyTimes="0;0.5617913448190655;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.987563416897393s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="27"
                                        cy="119.12060445382853"
                                        r="14"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="119.12060445382853;-8.664878445136598"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.51470333191229s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="14;0;0"
                                            keyTimes="0;0.8658717122330923;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.51470333191229s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="79"
                                        cy="121.40703161725776"
                                        r="11"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="121.40703161725776;-9.629824418578792"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.883575788086937s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="11;0;0"
                                            keyTimes="0;0.5607051578490321;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.883575788086937s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="64"
                                        cy="120.30143562889049"
                                        r="8"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="120.30143562889049;3.061527069666962"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8138199484799509s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.5122210436119842;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8138199484799509s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="11"
                                        cy="108.68237096798842"
                                        r="8"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="108.68237096798842;0.5118256233180833"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7376362461066581s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.7510521348464444;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7376362461066581s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="25"
                                        cy="136.7024766316658"
                                        r="24"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="136.7024766316658;-17.795612013037758"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.14778473609705278s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="24;0;0"
                                            keyTimes="0;0.6612471787843645;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.14778473609705278s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="23"
                                        cy="103.36354110743537"
                                        r="3"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="103.36354110743537;12.678005163894246"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4408953470483499s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="3;0;0"
                                            keyTimes="0;0.7581859399165125;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4408953470483499s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="54"
                                        cy="133.2247400989416"
                                        r="32"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="133.2247400989416;-13.809188185345292"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.11197400029973759s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="32;0;0"
                                            keyTimes="0;0.5211837248957382;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.11197400029973759s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="64"
                                        cy="128.97926827378075"
                                        r="9"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="128.97926827378075;-4.006491127213257"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.38156687137010725s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="9;0;0"
                                            keyTimes="0;0.8567382970219799;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.38156687137010725s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="51"
                                        cy="145.8889702338193"
                                        r="28"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="145.8889702338193;-17.762263581044163"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.39600170703212334s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="28;0;0"
                                            keyTimes="0;0.9687890764223426;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.39600170703212334s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="57"
                                        cy="137.1987095504969"
                                        r="21"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="137.1987095504969;-19.58757911184826"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.17381668717830068s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="21;0;0"
                                            keyTimes="0;0.5998262721985467;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.17381668717830068s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="34"
                                        cy="137.57344134735638"
                                        r="29"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="137.57344134735638;-20.70890066600327"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6060623515338126s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="29;0;0"
                                            keyTimes="0;0.7745402779089443;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6060623515338126s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="46"
                                        cy="139.21951145545762"
                                        r="32"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="139.21951145545762;-20.10014568838095"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.08986392529034126s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="32;0;0"
                                            keyTimes="0;0.8462762510052099;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.08986392529034126s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="64"
                                        cy="135.56616139165064"
                                        r="23"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="135.56616139165064;-22.787372621505078"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7597537578314093s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="23;0;0"
                                            keyTimes="0;0.8509888094458229;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7597537578314093s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="18"
                                        cy="126.45895234291791"
                                        r="17"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="126.45895234291791;-14.229754954677055"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.13169345748916794s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="17;0;0"
                                            keyTimes="0;0.8911360325480149;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.13169345748916794s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="40"
                                        cy="138.60439473159133"
                                        r="35"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="138.60439473159133;-31.321196139154264"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5983809385319168s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="35;0;0"
                                            keyTimes="0;0.7948931212962924;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5983809385319168s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="84"
                                        cy="129.16499429506368"
                                        r="13"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="129.16499429506368;1.510370495547047"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.29451168507789593s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="13;0;0"
                                            keyTimes="0;0.9395075194388327;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.29451168507789593s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="52"
                                        cy="145.7315397042732"
                                        r="31"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="145.7315397042732;-11.690582354358476"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6356309844486554s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="31;0;0"
                                            keyTimes="0;0.7438195476830499;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6356309844486554s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="51"
                                        cy="148.8699847026332"
                                        r="40"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="148.8699847026332;-29.309200240532128"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8930585100065036s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="40;0;0"
                                            keyTimes="0;0.8412689771248711;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8930585100065036s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="47"
                                        cy="160.81016968082218"
                                        r="46"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="160.81016968082218;-31.469722391806158"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.26282527604466144s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="46;0;0"
                                            keyTimes="0;0.898698197749635;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.26282527604466144s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="45"
                                        cy="145.67368234621873"
                                        r="40"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="145.67368234621873;-26.74635017399916"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.41105397423831036s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="40;0;0"
                                            keyTimes="0;0.7041052900549664;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.41105397423831036s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="51"
                                        cy="148.73239245765149"
                                        r="44"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="148.73239245765149;-31.127008229403508"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6980880265963139s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="44;0;0"
                                            keyTimes="0;0.9287800350833783;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6980880265963139s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="78"
                                        cy="123.5734043315124"
                                        r="22"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="123.5734043315124;-11.907051522418104"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7190640202329999s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="22;0;0"
                                            keyTimes="0;0.5165140399257255;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7190640202329999s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="49"
                                        cy="122.18436282786459"
                                        r="11"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="122.18436282786459;8.06056649140001"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8477903877468649s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="11;0;0"
                                            keyTimes="0;0.532097901390774;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8477903877468649s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="32"
                                        cy="124.43491471764484"
                                        r="22"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="124.43491471764484;-18.577782391023174"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.25547496206364384s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="22;0;0"
                                            keyTimes="0;0.5725891631565393;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.25547496206364384s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="66"
                                        cy="122.84597217184562"
                                        r="6"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="122.84597217184562;-3.773627277877555"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6288349738648522s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="6;0;0"
                                            keyTimes="0;0.8343363033943814;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6288349738648522s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="43"
                                        cy="140.4896710859736"
                                        r="31"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="140.4896710859736;-29.54032222224"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9563772176366214s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="31;0;0"
                                            keyTimes="0;0.7232342421949792;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9563772176366214s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="59"
                                        cy="126.04744234073769"
                                        r="23"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="126.04744234073769;-5.496901934564249"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.12189995276050536s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="23;0;0"
                                            keyTimes="0;0.5390647545098842;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.12189995276050536s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="53"
                                        cy="146.54167072850413"
                                        r="41"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="146.54167072850413;-35.34127630267442"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6158868531963937s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="41;0;0"
                                            keyTimes="0;0.8584431244577752;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6158868531963937s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="37"
                                        cy="130.29674284312378"
                                        r="25"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="130.29674284312378;-9.759815353270609"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.19856122878361138s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="25;0;0"
                                            keyTimes="0;0.8715159443456836;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.19856122878361138s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="58"
                                        cy="131.27684347345033"
                                        r="19"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="131.27684347345033;-14.585823003519327"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7457811950203543s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="19;0;0"
                                            keyTimes="0;0.8108019965052339;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7457811950203543s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="81"
                                        cy="121.9380182868976"
                                        r="18"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="121.9380182868976;-1.9271688056024487"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.02070638718832063s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="18;0;0"
                                            keyTimes="0;0.5216327283418531;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.02070638718832063s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="67"
                                        cy="136.90648746734902"
                                        r="25"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="136.90648746734902;-18.700341881693333"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.0896560650506808s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="25;0;0"
                                            keyTimes="0;0.9139605823941888;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.0896560650506808s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="33"
                                        cy="140.2404982702385"
                                        r="28"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="140.2404982702385;-19.619179422981475"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4123638967777361s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="28;0;0"
                                            keyTimes="0;0.5460035727358695;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4123638967777361s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="47"
                                        cy="146.6519088562319"
                                        r="45"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="146.6519088562319;-29.75948814582788"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.426297254194131s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="45;0;0"
                                            keyTimes="0;0.5637516357424084;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.426297254194131s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="73"
                                        cy="134.30556390246628"
                                        r="15"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="134.30556390246628;-4.952367207054342"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.515911392309313s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="15;0;0"
                                            keyTimes="0;0.6858213638512759;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.515911392309313s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="56"
                                        cy="158.79429653295938"
                                        r="44"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="158.79429653295938;-37.2972930013461"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5227321700504683s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="44;0;0"
                                            keyTimes="0;0.7879429748242468;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5227321700504683s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="27"
                                        cy="136.87368330420549"
                                        r="18"
                                        fill="#df55ea"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="136.87368330420549;1.4222168288020143"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.009393160341101714s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="18;0;0"
                                            keyTimes="0;0.7202236339933954;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.009393160341101714s"
                                        ></animate>
                                    </circle>
                                </g>
                                <g filter="url(#ldio-pykm2m4f3o-filter)">
                                    <circle
                                        cx="62"
                                        cy="129.0273698540674"
                                        r="20"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="129.0273698540674;-5.4888928537718495"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8981305503229495s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="20;0;0"
                                            keyTimes="0;0.6303718408902355;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8981305503229495s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="47"
                                        cy="143.85049906352722"
                                        r="25"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="143.85049906352722;-15.320844906703703"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.47377465138515684s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="25;0;0"
                                            keyTimes="0;0.9179876662526869;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.47377465138515684s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="58"
                                        cy="128.82876722221988"
                                        r="15"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="128.82876722221988;-5.901919962209444"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.04385227949651194s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="15;0;0"
                                            keyTimes="0;0.9578269829786554;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.04385227949651194s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="26"
                                        cy="128.9780668266698"
                                        r="23"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="128.9780668266698;-22.109629890551933"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.39323875604607084s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="23;0;0"
                                            keyTimes="0;0.8311835486544983;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.39323875604607084s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="47"
                                        cy="134.24701619254682"
                                        r="30"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="134.24701619254682;-11.517570754534749"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.21859080008629594s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="30;0;0"
                                            keyTimes="0;0.6634457431217778;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.21859080008629594s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="18"
                                        cy="120.45497311345486"
                                        r="11"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="120.45497311345486;1.1148513799639126"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.1647378177302603s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="11;0;0"
                                            keyTimes="0;0.6424996654697915;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.1647378177302603s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="45"
                                        cy="123.7549307393908"
                                        r="6"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="123.7549307393908;10.855059326965392"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8986619812386849s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="6;0;0"
                                            keyTimes="0;0.5712467470508411;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8986619812386849s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="68"
                                        cy="119.97205720920427"
                                        r="16"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="119.97205720920427;-11.482397587036289"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.020802723783546195s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="16;0;0"
                                            keyTimes="0;0.9251161112153478;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.020802723783546195s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="26"
                                        cy="120.66154205388906"
                                        r="8"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="120.66154205388906;2.861546031095589"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4183829464231328s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.703308010296528;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4183829464231328s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="92"
                                        cy="107.15092756134645"
                                        r="4"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="107.15092756134645;2.72115217483499"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.18911512560428023s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="4;0;0"
                                            keyTimes="0;0.8783975094395543;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.18911512560428023s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="27"
                                        cy="132.7730393648958"
                                        r="26"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="132.7730393648958;-25.68239943557108"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7247978566256066s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="26;0;0"
                                            keyTimes="0;0.8216076470059589;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7247978566256066s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="45"
                                        cy="110.03729630077373"
                                        r="10"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="110.03729630077373;-4.788412570144671"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.43535836146809004s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="10;0;0"
                                            keyTimes="0;0.7802982808316404;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.43535836146809004s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="60"
                                        cy="107.84073715486828"
                                        r="7"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="107.84073715486828;-3.4332324870844593"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7948328028116136s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="7;0;0"
                                            keyTimes="0;0.5785153543895434;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7948328028116136s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="46"
                                        cy="115.38259485724865"
                                        r="5"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="115.38259485724865;0.03184007457790905"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5438783801270766s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="5;0;0"
                                            keyTimes="0;0.6494223792220166;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5438783801270766s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="40"
                                        cy="128.62926801870404"
                                        r="19"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="128.62926801870404;-14.778927546590605"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9299887893969669s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="19;0;0"
                                            keyTimes="0;0.8712903231783784;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9299887893969669s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="32"
                                        cy="107.82277873453344"
                                        r="7"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="107.82277873453344;8.786391589488908"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.288726573245186s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="7;0;0"
                                            keyTimes="0;0.7679482869936335;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.288726573245186s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="46"
                                        cy="144.21653224877588"
                                        r="32"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="144.21653224877588;-26.677954356694283"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.27366276331260964s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="32;0;0"
                                            keyTimes="0;0.8268296418448234;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.27366276331260964s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="47"
                                        cy="113.38875092244781"
                                        r="3"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="113.38875092244781;5.889178058068033"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.30233105249811665s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="3;0;0"
                                            keyTimes="0;0.7874171971579593;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.30233105249811665s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="39"
                                        cy="137.47855032935905"
                                        r="32"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="137.47855032935905;-15.737069041896063"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.45291821352815265s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="32;0;0"
                                            keyTimes="0;0.9896766796563827;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.45291821352815265s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="35"
                                        cy="134.3080772338398"
                                        r="20"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="134.3080772338398;-15.914967682915666"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7099935217491742s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="20;0;0"
                                            keyTimes="0;0.9212640125213463;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7099935217491742s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="32"
                                        cy="138.03572925149194"
                                        r="29"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="138.03572925149194;-12.915053189568827"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.14652906819645573s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="29;0;0"
                                            keyTimes="0;0.5030001252325893;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.14652906819645573s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="46"
                                        cy="124.37901863571045"
                                        r="10"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="124.37901863571045;0.16762562823955385"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9406519308743839s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="10;0;0"
                                            keyTimes="0;0.5681814012940425;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9406519308743839s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="75"
                                        cy="136.5903066201782"
                                        r="21"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="136.5903066201782;-18.954751989317216"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4874012828210188s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="21;0;0"
                                            keyTimes="0;0.5824337582081408;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4874012828210188s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="40"
                                        cy="139.94652129678738"
                                        r="21"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="139.94652129678738;-20.73898018890571"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.17735786372464046s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="21;0;0"
                                            keyTimes="0;0.8554775286083013;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.17735786372464046s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="62"
                                        cy="133.17224866784522"
                                        r="31"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="133.17224866784522;-20.221703141789384"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.1272168883066429s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="31;0;0"
                                            keyTimes="0;0.5976874605367275;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.1272168883066429s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="76"
                                        cy="114.92611094258083"
                                        r="10"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="114.92611094258083;-6.017802061104889"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.1031734788403158s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="10;0;0"
                                            keyTimes="0;0.9807639112292104;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.1031734788403158s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="65"
                                        cy="139.9124678213686"
                                        r="24"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="139.9124678213686;-9.451985050039156"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5301157403700911s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="24;0;0"
                                            keyTimes="0;0.5595619939029113;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5301157403700911s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="53"
                                        cy="126.05341925712193"
                                        r="14"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="126.05341925712193;-0.07507315600640396"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3981658962387651s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="14;0;0"
                                            keyTimes="0;0.6417108403946424;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3981658962387651s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="70"
                                        cy="118.56492099012345"
                                        r="8"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="118.56492099012345;5.916138513523659"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9666189108948384s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.6985691973691353;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9666189108948384s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="59"
                                        cy="145.8966725958701"
                                        r="32"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="145.8966725958701;-20.155933384604623"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6222861644340469s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="32;0;0"
                                            keyTimes="0;0.758162953505016;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6222861644340469s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="78"
                                        cy="119.89507025311131"
                                        r="3"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="119.89507025311131;11.386805715993415"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6299745126793348s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="3;0;0"
                                            keyTimes="0;0.5737484259634652;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6299745126793348s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="59"
                                        cy="132.39238046028234"
                                        r="24"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="132.39238046028234;-9.41180970822175"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.1754865630547493s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="24;0;0"
                                            keyTimes="0;0.708593412093349;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.1754865630547493s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="78"
                                        cy="115.04859023611756"
                                        r="2"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="115.04859023611756;7.35360457240327"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.40368137799728054s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="2;0;0"
                                            keyTimes="0;0.8880483559499499;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.40368137799728054s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="60"
                                        cy="142.55700843428454"
                                        r="31"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="142.55700843428454;-14.959644768267708"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.14555756683666887s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="31;0;0"
                                            keyTimes="0;0.8565497342956718;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.14555756683666887s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="57"
                                        cy="116.01759424941012"
                                        r="8"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="116.01759424941012;0.4141936226157643"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6036541441615266s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.5206975954454626;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6036541441615266s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="30"
                                        cy="138.36434525433077"
                                        r="24"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="138.36434525433077;-9.836307731954037"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.1914942398479833s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="24;0;0"
                                            keyTimes="0;0.7979448200446494;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.1914942398479833s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="74"
                                        cy="130.41393621826518"
                                        r="15"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="130.41393621826518;-4.117053541249"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3677618067536643s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="15;0;0"
                                            keyTimes="0;0.7328198089283569;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3677618067536643s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="67"
                                        cy="130.06398381976445"
                                        r="24"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="130.06398381976445;-6.643827782959384"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9462935702884807s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="24;0;0"
                                            keyTimes="0;0.9922943971401206;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9462935702884807s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="30"
                                        cy="131.10114244327625"
                                        r="21"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="131.10114244327625;-20.354501657188656"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4186195351994424s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="21;0;0"
                                            keyTimes="0;0.6523080926738776;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4186195351994424s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="57"
                                        cy="136.6706968483401"
                                        r="30"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="136.6706968483401;-29.933748256607288"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3591090496384337s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="30;0;0"
                                            keyTimes="0;0.6577541840948669;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3591090496384337s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="63"
                                        cy="110.61685064992913"
                                        r="10"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="110.61685064992913;-3.900619279261872"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9852332532552803s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="10;0;0"
                                            keyTimes="0;0.6230360549796591;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9852332532552803s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="55"
                                        cy="149.66469657331535"
                                        r="32"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="149.66469657331535;-28.367722953173004"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3920578716365233s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="32;0;0"
                                            keyTimes="0;0.7622925811872499;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3920578716365233s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="20"
                                        cy="115.1048062498263"
                                        r="9"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="115.1048062498263;-3.9353118593188006"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.42233530714192846s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="9;0;0"
                                            keyTimes="0;0.7397669824326608;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.42233530714192846s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="85"
                                        cy="114.45584580004655"
                                        r="9"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="114.45584580004655;-3.4604446646925178"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8130100821944977s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="9;0;0"
                                            keyTimes="0;0.7341502580959499;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8130100821944977s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="74"
                                        cy="117.56961393192138"
                                        r="9"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="117.56961393192138;7.032179398536449"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.10116131067908096s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="9;0;0"
                                            keyTimes="0;0.6263244077954948;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.10116131067908096s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="28"
                                        cy="123.37023023369802"
                                        r="8"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="123.37023023369802;1.4038594441061019"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.22770078688567574s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.9500504346932759;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.22770078688567574s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="24"
                                        cy="132.1464945510989"
                                        r="16"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="132.1464945510989;-13.929010888822141"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3698260489363292s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="16;0;0"
                                            keyTimes="0;0.7590287515648784;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3698260489363292s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="51"
                                        cy="126.3597284629917"
                                        r="11"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="126.3597284629917;-0.17846011762161496"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.04400785658780815s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="11;0;0"
                                            keyTimes="0;0.5813572648269498;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.04400785658780815s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="27"
                                        cy="125.03456925204074"
                                        r="22"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="125.03456925204074;-8.199039220216914"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6744208096990303s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="22;0;0"
                                            keyTimes="0;0.9076157377004167;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6744208096990303s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="44"
                                        cy="150.69744052955798"
                                        r="31"
                                        fill="#f9c17b"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="150.69744052955798;-26.173507696056674"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.06575244847979422s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="31;0;0"
                                            keyTimes="0;0.841791119216167;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.06575244847979422s"
                                        ></animate>
                                    </circle>
                                </g>
                                <g filter="url(#ldio-pykm2m4f3o-filter)">
                                    <circle
                                        cx="57"
                                        cy="128.68917110262953"
                                        r="14"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="128.68917110262953;-8.733046535730674"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7125412775062556s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="14;0;0"
                                            keyTimes="0;0.7519189416382313;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7125412775062556s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="77"
                                        cy="130.10038425394677"
                                        r="11"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="130.10038425394677;3.031447457783216"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5344052064390361s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="11;0;0"
                                            keyTimes="0;0.9715516992228219;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5344052064390361s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="57"
                                        cy="110.42786578574875"
                                        r="5"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="110.42786578574875;3.4904330781418977"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3800537286629464s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="5;0;0"
                                            keyTimes="0;0.8482392792603101;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3800537286629464s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="59"
                                        cy="135.82064505955339"
                                        r="17"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="135.82064505955339;-13.793631337311341"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.41345400259973486s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="17;0;0"
                                            keyTimes="0;0.9005945373710753;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.41345400259973486s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="62"
                                        cy="111.03646186140143"
                                        r="11"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="111.03646186140143;-4.986931103907336"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4209477810179647s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="11;0;0"
                                            keyTimes="0;0.8971025663938866;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4209477810179647s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="67"
                                        cy="126.84785942808547"
                                        r="14"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="126.84785942808547;-6.0024120903487"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6811515972192104s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="14;0;0"
                                            keyTimes="0;0.7282094785424434;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6811515972192104s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="24"
                                        cy="123.28471613178091"
                                        r="8"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="123.28471613178091;8.202076054776725"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7365347352245866s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.6874625876636092;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7365347352245866s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="87"
                                        cy="110.63861694733784"
                                        r="2"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="110.63861694733784;5.336701917872128"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6927223803267317s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="2;0;0"
                                            keyTimes="0;0.7126792295891337;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6927223803267317s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="19"
                                        cy="117.7506526167342"
                                        r="11"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="117.7506526167342;-4.481807419276799"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6262713192019296s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="11;0;0"
                                            keyTimes="0;0.7071460743985357;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6262713192019296s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="51"
                                        cy="111.44157438927796"
                                        r="10"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="111.44157438927796;-6.444879213207568"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.915966661308248s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="10;0;0"
                                            keyTimes="0;0.7592181999448676;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.915966661308248s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="39"
                                        cy="123.7481884458561"
                                        r="9"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="123.7481884458561;-7.783095041613378"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5212446870452689s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="9;0;0"
                                            keyTimes="0;0.9080426329164751;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5212446870452689s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="40"
                                        cy="126.27345607582004"
                                        r="10"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="126.27345607582004;9.114380524027602"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.39557355181557585s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="10;0;0"
                                            keyTimes="0;0.516976068807569;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.39557355181557585s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="53"
                                        cy="133.96376925524916"
                                        r="14"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="133.96376925524916;-10.299144372756658"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.31277958467494327s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="14;0;0"
                                            keyTimes="0;0.5874644508876171;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.31277958467494327s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="48"
                                        cy="120.86240619467033"
                                        r="6"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="120.86240619467033;9.511171844571514"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.25797905826853373s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="6;0;0"
                                            keyTimes="0;0.8548101068297029;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.25797905826853373s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="91"
                                        cy="118.69462366582698"
                                        r="6"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="118.69462366582698;5.090843323345659"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4584084927259766s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="6;0;0"
                                            keyTimes="0;0.9347510303164185;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4584084927259766s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="46"
                                        cy="116.21700892413207"
                                        r="4"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="116.21700892413207;2.2170925772769126"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.024966514526252537s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="4;0;0"
                                            keyTimes="0;0.5083115603098167;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.024966514526252537s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="51"
                                        cy="113.18711960533491"
                                        r="2"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="113.18711960533491;15.96130637660746"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.18761698595071308s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="2;0;0"
                                            keyTimes="0;0.7744944631966295;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.18761698595071308s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="86"
                                        cy="123.61445035698151"
                                        r="11"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="123.61445035698151;-9.469895662679887"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6841667684040031s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="11;0;0"
                                            keyTimes="0;0.7169760386368497;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6841667684040031s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="78"
                                        cy="118.77113214589377"
                                        r="9"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="118.77113214589377;4.697924707547742"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.24417256546712518s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="9;0;0"
                                            keyTimes="0;0.7469988866229115;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.24417256546712518s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="60"
                                        cy="130.97242680576602"
                                        r="16"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="130.97242680576602;-4.793486156700823"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.14530384175712996s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="16;0;0"
                                            keyTimes="0;0.6609606972754573;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.14530384175712996s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="46"
                                        cy="127.98714770873804"
                                        r="9"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="127.98714770873804;2.8648268893575537"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4810186736406945s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="9;0;0"
                                            keyTimes="0;0.9784868695239434;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4810186736406945s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="22"
                                        cy="111.10883111604187"
                                        r="3"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="111.10883111604187;-0.2566570279845033"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5311738704325575s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="3;0;0"
                                            keyTimes="0;0.8830080928309166;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5311738704325575s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="29"
                                        cy="130.81071997643372"
                                        r="13"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="130.81071997643372;-4.844970040895916"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.12046189665842166s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="13;0;0"
                                            keyTimes="0;0.9217786509707041;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.12046189665842166s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="51"
                                        cy="120.54923804354382"
                                        r="15"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="120.54923804354382;-9.542089685402345"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6659810331322735s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="15;0;0"
                                            keyTimes="0;0.6194335967280802;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6659810331322735s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="29"
                                        cy="119.67128238114606"
                                        r="13"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="119.67128238114606;5.4310973734657395"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.24841182716008525s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="13;0;0"
                                            keyTimes="0;0.8217088045131898;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.24841182716008525s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="13"
                                        cy="128.38416369101498"
                                        r="9"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="128.38416369101498;9.471756271054321"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6452409858683212s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="9;0;0"
                                            keyTimes="0;0.9911282023198669;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6452409858683212s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="39"
                                        cy="129.39815317211628"
                                        r="10"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="129.39815317211628;2.344220259576435"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8430956769952034s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="10;0;0"
                                            keyTimes="0;0.7145928767472225;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8430956769952034s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="77"
                                        cy="116.4177061147268"
                                        r="8"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="116.4177061147268;-3.933760275666975"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.29975839724279063s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.892312561960794;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.29975839724279063s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="15"
                                        cy="119.75360130572467"
                                        r="15"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="119.75360130572467;-13.204214180187419"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.17142697715387478s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="15;0;0"
                                            keyTimes="0;0.7721945423345168;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.17142697715387478s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="60"
                                        cy="113.53450999358643"
                                        r="8"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="113.53450999358643;-6.020449263656384"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.14431281974050103s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.783581424199236;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.14431281974050103s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="26"
                                        cy="116.36694369401465"
                                        r="2"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="116.36694369401465;9.922677599570509"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.19780996113248395s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="2;0;0"
                                            keyTimes="0;0.7867286647012216;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.19780996113248395s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="18"
                                        cy="126.3888019666585"
                                        r="8"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="126.3888019666585;0.4174480845538202"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8464843623754066s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.901245194878715;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.8464843623754066s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="22"
                                        cy="123.85512214572341"
                                        r="4"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="123.85512214572341;-2.322439552241616"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5978325264794122s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="4;0;0"
                                            keyTimes="0;0.6145779383275712;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5978325264794122s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="65"
                                        cy="121.81531123407508"
                                        r="10"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="121.81531123407508;-8.408371984397816"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.20328597541739435s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="10;0;0"
                                            keyTimes="0;0.7427240012084847;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.20328597541739435s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="73"
                                        cy="119.5211549162743"
                                        r="12"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="119.5211549162743;-7.4484424858861935"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.02028257242285103s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="12;0;0"
                                            keyTimes="0;0.8514243312912126;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.02028257242285103s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="14"
                                        cy="111.8886540794026"
                                        r="9"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="111.8886540794026;-4.944995649580576"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.28723627358667336s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="9;0;0"
                                            keyTimes="0;0.9898417549725052;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.28723627358667336s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="6"
                                        cy="116.64802990588083"
                                        r="3"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="116.64802990588083;9.317681815425718"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9103650175796649s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="3;0;0"
                                            keyTimes="0;0.5943737037692377;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.9103650175796649s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="8"
                                        cy="114.10939880783302"
                                        r="3"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="114.10939880783302;0.581537445113292"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6815454933482177s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="3;0;0"
                                            keyTimes="0;0.7382453591850656;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6815454933482177s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="40"
                                        cy="120.40232413840009"
                                        r="15"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="120.40232413840009;-10.780734616162722"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5883383551032193s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="15;0;0"
                                            keyTimes="0;0.7296107426885434;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5883383551032193s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="75"
                                        cy="119.54914572951165"
                                        r="15"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="119.54914572951165;-8.788390921312894"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6326941622577735s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="15;0;0"
                                            keyTimes="0;0.8161034482481079;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6326941622577735s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="10"
                                        cy="108.65759961044904"
                                        r="6"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="108.65759961044904;-4.603975183310664"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.798637754535114s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="6;0;0"
                                            keyTimes="0;0.912412517003476;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.798637754535114s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="22"
                                        cy="122.71200376249571"
                                        r="12"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="122.71200376249571;3.453065469121686"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.014979680141373963s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="12;0;0"
                                            keyTimes="0;0.7114151792913815;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.014979680141373963s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="51"
                                        cy="122.8290878968129"
                                        r="12"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="122.8290878968129;-6.201142148806278"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.03066084387566681s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="12;0;0"
                                            keyTimes="0;0.7524101886947218;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.03066084387566681s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="75"
                                        cy="117.59946849755406"
                                        r="8"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="117.59946849755406;-5.0566116003763035"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7404671502864324s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="8;0;0"
                                            keyTimes="0;0.6084051376561901;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.7404671502864324s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="70"
                                        cy="133.58587067780599"
                                        r="16"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="133.58587067780599;-11.243227320592826"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.21108559618451417s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="16;0;0"
                                            keyTimes="0;0.6734088373368448;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.21108559618451417s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="27"
                                        cy="120.87115691138743"
                                        r="15"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="120.87115691138743;-7.233793692486601"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6693567028715632s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="15;0;0"
                                            keyTimes="0;0.6057899665073428;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.6693567028715632s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="59"
                                        cy="115.57660290353176"
                                        r="4"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="115.57660290353176;-2.1500432378870125"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5807623963708759s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="4;0;0"
                                            keyTimes="0;0.7941055942997277;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.5807623963708759s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="67"
                                        cy="124.06652572561855"
                                        r="10"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="124.06652572561855;0.43254262943523614"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3273587432384988s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="10;0;0"
                                            keyTimes="0;0.9890652773635277;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.3273587432384988s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="29"
                                        cy="121.7484454229725"
                                        r="6"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="121.7484454229725;7.7306193228001625"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4521363122091935s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="6;0;0"
                                            keyTimes="0;0.7917305796958983;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.4521363122091935s"
                                        ></animate>
                                    </circle>
                                    <circle
                                        cx="34"
                                        cy="114.99673504968159"
                                        r="4"
                                        fill="#68d0e0"
                                    >
                                        <animate
                                            attributeName="cy"
                                            values="114.99673504968159;0.9418088813365024"
                                            keyTimes="0;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.37824763288507146s"
                                        ></animate>
                                        <animate
                                            attributeName="r"
                                            values="4;0;0"
                                            keyTimes="0;0.6271231739277429;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            begin="-0.37824763288507146s"
                                        ></animate>
                                    </circle>
                                </g>
                            </svg>
                            <h2 className="mx-3 my-2 color-white">{text}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
